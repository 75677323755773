window.betaSignup = function betaSignup() {
  return {
    thisYear: (new Date()).getFullYear(),
    loading: false,
    emailSubmitted: false,
    showContactOverlay: false,
    isCopied: false,
    copyContactEmail() {
      navigator.clipboard.writeText('question@jambiscuit.app');
      this.isCopied = true;
    },
    async submit(event) {
      let formData = new FormData(event.target);
      let object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      this.loading = true;

      const response = await fetch("https://formbold.com/s/6lqyd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });
      const result = await response.json();

      if (response.ok) {
        this.status = result.message || "Success";
        this.emailSubmitted = true;
        this.loading = false;
        event.target.reset();
      }
      this.status = result.message || "Something went wrong";
      this.loading = false;
      setTimeout(() => (this.status = ""), 3000);
    }
  }
}
